import NextLink from 'next/link';
import React from 'react';
import clsx from 'clsx';

import { VisuallyHidden, Text, Image, ModuleContainer } from 'ui';

import { CmsModel } from 'api-types';

import styles from './M60USP.module.css';

export type M60USPProps = CmsModel.M060UspModule;

export const M60USP = ({ uspList, ...rest }: M60USPProps) => {
    const UspContent = ({ text, image }: CmsModel.UspItem) => {
        return (
            <>
                {image && (
                    <div className={clsx(styles.image)}>
                        <Image {...image} sizes="60px" />
                    </div>
                )}
                <div className={clsx(styles.content)}>{text && <Text children={text} />}</div>
            </>
        );
    };

    return (
        <ModuleContainer hasGutter {...rest} >
            <ul className={clsx(styles.grid)}>
                {uspList?.map(({ text, image, link }, index) => {
                    return (
                        <li className={clsx(styles.item)} key={index}>
                            {link?.url ? (
                                <NextLink href={link.url} passHref>
                                    <a className={clsx(styles.link)} target={link.target} title={link.title}>
                                        <VisuallyHidden>{link.title}</VisuallyHidden>
                                        {UspContent({ text, image, link })}
                                    </a>
                                </NextLink>
                            ) : (
                                UspContent({ text, image, link })
                            )}
                        </li>
                    );
                })}
            </ul>
        </ModuleContainer>
    );
};
